@charset "utf-8";

// Update Bulma's global variables
$family-sans-serif: "Montserrat", sans-serif;
$info: #0A71D0;
$link: $info;

@import "~bulma/bulma";

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.title {
  font-weight: 700;
}

.subtitle {
  color: #252D39;
  font-weight: 600;
  font-size: 1.04rem ;
}

#wrapper {
  flex: 1;
}

.logo-header {
  height: 62px;
  padding: 2px;
  margin: auto;
}

.edition-list .level {
  border-top: 1px solid #e5ecf3;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 15px;
  padding-top: 15px;
}

.edition-list .level:last-child {
  border-bottom: 1px solid #e5ecf3;
}

a.button {
  color: $info;
  font-weight: 600;
}

footer a,
.copyright {
  font-weight: 500;
  color: $link;
}
